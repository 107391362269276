<template>
  <v-overlay v-model="overlay" class="align-center justify-center" @click="datas()">
    <div class="d-flex flex-column align-items-center justify-center">
      <div class="lds-roller mb-3">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <p class="loaderText m-0" v-if="loadtext">We are search for Best Fare.</p>
      <p class="waitText m-0" v-if="waitText">Please wait</p>
    </div>
  </v-overlay>
</template>


<script>
export default{
    data(){
        return{
            overlay: true,
            loadtext:false,
            waitText:false,
        }
    },
    methods:{
        datas(){
            console.log(this.overlay,'overlayoverlay')
        }
    },
    watch: {

      "$route.path": {
      immediate: true,
      handler(newValue) {
        if (newValue === "/search") {
          this.loadtext = true;
          this.waitText = true;
        } 
        else if(newValue === "/bookingView"){
          this.waitText = true;
        }
        else {
          this.loadtext = false;
          this.waitText = false;
        }
      },
    },
        overlay(newValue) {
      this.overlay=true
            console.log('Overlay state changed to:', newValue);
      },
    },
}
</script>

<style>
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}


@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}



.loaderText{
    font-size: 14px;
    font-weight: 500;
    color: white;
}
.waitText{
  font-size: 14px;
  font-weight: 500;
  color: white;
}
.waitText:after {
  content: ' .';
  font-size: 22px;
  animation: dots 1s steps(5, end) infinite;}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: white;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 white;}}




</style>
