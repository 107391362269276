<template>
  <v-app v-if="loading">
    <v-main class="bg-pages">
      <div v-if="datashow">
          <router-view />
      </div>
    </v-main>
  </v-app>

  <div v-if="dataLoader">
    <dataloader></dataloader>
  </div>

  <!-- <div v-if="dotLoader">
    <dotLoader></dotLoader>
  </div> -->
</template>

<script>
import axios from "axios";
import dataloader from "@/components/dataLoder.vue";
import CryptoJS from "crypto-js";

import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";

export default {
  name: "App",
  components: {
    dataloader,
  },
  data: () => ({
    navbar: true,
    food: true,
    domaintrue: false,
    datashow: true,
    countryname: "",
    selectedHeader: "headerCom1",
    selectedFooter: "footerCom",
    loading: false,

    dataLoader: true,
    // dotLoader: true,

    dataDomain: "",
    dataDomainData: "",
    SearchDomain: "",
    searchDomaintitle: "",

    configReq: {
      service_type: "GetPortalConfigRQ",
      api_ver: "1",
      payload: {
        domain_name: "mannb2b-uat.vibrace.com",
        env: "CERT",
        language: "EN",
        token: "jsdjOJDKJ7675",
      },
    },

    configUser_Id: "",
    tawktoApi: "",
  }),

  methods: {
    async envPortal() {
      let hostname = window.location.hostname;
      console.log(hostname, "hshshshs....");
      // let hostname = "localhost";
      if (hostname == "localhost") {
        console.log(hostname, "localhost...");
      } else {
        this.configReq.payload.domain_name = hostname;
      }

      // this.datashow = true;

      console.log(this.configReq, "configReqconfigReq");

      await axios
        .get("https://otapi.mann-uat.vibrace.com/api/getportalconfig", {
          params: this.configReq,
          headers: {
            "Content-Type": "application/json",
          },
        })

        .then((response) => {
          if (response) {
            // this.dotLoader = false;
            this.loading = true;
            this.dataLoader = false;
            let configResponse = response.data;

            console.log(configResponse, "DelayDelayDelayDelayDelay");

            this.dataDomainData =
              configResponse.payload.portal_configuration.geoip_location.country_domain.replace(
                "https://",
                ""
              );
            this.dataDomain =
              this.dataDomainData.charAt(0).toUpperCase() +
              this.dataDomainData.slice(1);
            this.SearchDomain = this.configReq.payload.domain_name;
            this.searchDomaintitle =
              configResponse.payload.portal_configuration.site_title;
            this.countryname =
              configResponse.payload.portal_configuration.country.name;
        

            let routerPath = location.href;
            if (
              this.dataDomainData.includes(this.SearchDomain) ||
              routerPath.includes("env=cert")
            ) {
              console.log("pppppppaduhdh");
              this.domaintrue = false;
            } else {
              this.domaintrue = true;
            }

            // Encrypt data using CryptoJS
            const secretKey = "portal_config_datas";
            const encryptedData = CryptoJS.AES.encrypt(
              JSON.stringify(configResponse),
              secretKey
            ).toString();

            localStorage.setItem("portalData", encryptedData);

            // localStorage.setItem("portalData", JSON.stringify(configResponse))

            if (configResponse) {
              this.datashow = true;
            }
            this.indexHeader();
          }
        })
        .catch((error) => {
          console.log("Error fetching data:", error);
          this.dataLoader = false;
          // alert('Something went difficulties...')
          setTimeout(() => {
            this.envPortal();
          }, 5000);

          this.loading = false;
          
        });
    },

    indexHeader() {
      const getConfigData = getConfigDataFromLocalStorage();

      if (getConfigData) {
        let portalTitle = getConfigData.payload.portal_configuration.site_title;
        let portalFavicon = getConfigData.payload.portal_configuration.favicon;

        let pluginTawkto = "";
        pluginTawkto =
          getConfigData.payload.portal_configuration.menus.enabled.plugins;
        // console.log(pluginWhats, 'dasdasdasda..1..1..1.1')
        if (pluginTawkto.includes("tawkto")) {
          // this.tawktoApi =
          //   getConfigData.payload.portal_configuration.API_endpoints.tawkto;
          console.log(this.tawktoApi, " this.tawktoApithis.tawktoApi ");
        } else {
          this.tawktoApi = "";
        }

        document.getElementById("siteTitle").innerText = portalTitle;
        document.getElementById("faviconPortal").href = portalFavicon;

        // Tawk.to code
        const s1 = document.createElement("script");
        const s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = this.tawktoApi;
        s1.charset = "UTF-8";
        s1.setAttribute("crossorigin", "*");
        s0.parentNode.insertBefore(s1, s0);
      }
    },
  },

  created() {
    getConfigDataFromLocalStorage(this);
    this.envPortal();

  },

  // watch: {
  //   "$route.path": {
  //     immediate: true,
  //     handler(newValue) {
  //       if (newValue === "/") {
  //         console.log('Routing Working...1')
  //         this.envPortal();
  //       } 
  //       // else {
  //       //   this.navbar = true;
  //       //   this.food = true;
  //       // }
  //       // console.log(newValue, '4545');
  //     },
  //   },
  // },

  // mounted() {
  //   window.__be = window.__be || {};
  //   window.__be.id = "65fc2b19daf9d00006243bf9";
  //   (function () {
  //     var be = document.createElement("script");
  //     be.type = "text/javascript";
  //     be.async = true;
  //     be.src =
  //       ("https:" == document.location.protocol ? "https://" : "http://") +
  //       "cdn.chatbot.com/widget/plugin.js";
  //     var s = document.getElementsByTagName("script")[0];
  //     s.parentNode.insertBefore(be, s);
  //   })();
  // },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300&family=Poppins:wght@300;400;500&display=swap");

* {
  font-family: "Poppins", sans-serif;
}

#navbar {
  display: block;
}

#food {
  display: block;
}

/* .tawk-branding{
  display:none !important;
} */

/* .bg-pages{
    background-image:url('./assets/bmbg2.svg');
  }  */

.bg-pages {
  background-image: url("./assets/bgimg/skyBluebg.jpg");
  background-position: center;
  background-size: cover;
  /* background: #17224e; */
  /* background: linear-gradient(90deg, rgba(45, 129, 253, 0.65), #c1c1c1); */
}

.f-size-20 {
  font-size: 20px;
}
.f-size-18 {
  font-size: 18px;
}
.f-size-16 {
  font-size: 16px;
}

.f-size-15 {
  font-size: 15px;
}

.f-size-14 {
  font-size: 14px;
}

.f-size-13 {
  font-size: 13px;
}

.f-size-12 {
  font-size: 12px;
}

.f-size-11 {
  font-size: 11px;
}

.f-size-10 {
  font-size: 10px;
}

.f-size-9 {
  font-size: 9px;
}

.fw-600 {
  font-weight: 600;
}
.fw-500 {
  font-weight: 500;
}
.fw-400 {
  font-weight: 400;
}
/* .bg-pages { */

/* background-image: url(https://andit.co/projects/html/and-tour/demo/assets/img/banner/bg.png); */
/* background: rgb(230, 217, 26); */
/* background: linear-gradient(277deg, rgba(230,217,26,1) 0%, rgba(51,175,167,1) 58%, rgba(253,45,164,1) 100%); */
/* background: linear-gradient(to right, rgb(45 129 253 / 65%), rgb(193 193 193)); */

/* margin-top:70px; */
/* background-image: url(https://andit.co/projects/html/and-tour/demo/assets/img/banner/bg.png); */
/* } */
</style>
